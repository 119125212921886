// 本文件是自动生成, 请勿修改
import business_analysis from '@/plugin/business_analysis/stage-config'
import cmsManager from '@/plugin/cms-manager/stage-config'
import facilityManager from '@/plugin/facility-manager/stage-config'
import financeReconciliation from '@/plugin/finance-reconciliation/stage-config'
import member_manager from '@/plugin/member_manager/stage-config'
import merchantManager from '@/plugin/merchant-manager/stage-config'
import miniprogramManager from '@/plugin/miniprogram-manager/stage-config'
import noteManager from '@/plugin/note-manager/stage-config'
import operation_manager from '@/plugin/operation_manager/stage-config'
import orderManager from '@/plugin/order-manager/stage-config'
import pointsManager from '@/plugin/points-manager/stage-config'
import purchaseManager from '@/plugin/purchase-manager/stage-config'
import pushMoney from '@/plugin/push-money/stage-config'
import roomManager from '@/plugin/room-manager/stage-config'
import settlementManager from '@/plugin/settlement-manager/stage-config'
import shopManager from '@/plugin/shop-manager/stage-config'
import storeInventory from '@/plugin/store-inventory/stage-config'
import storeTransactionManager from '@/plugin/storeTransactionManager/stage-config'
import supplyChainManager from '@/plugin/supplyChain-manager/stage-config'
import system_manager from '@/plugin/system_manager/stage-config'

const pluginsConfig = [
  business_analysis,
  cmsManager,
  facilityManager,
  financeReconciliation,
  member_manager,
  merchantManager,
  miniprogramManager,
  noteManager,
  operation_manager,
  orderManager,
  pointsManager,
  purchaseManager,
  pushMoney,
  roomManager,
  settlementManager,
  shopManager,
  storeInventory,
  storeTransactionManager,
  supplyChainManager,
  system_manager,
]

export default pluginsConfig
