const linCmsUiRouter = {
  route: null,
  name: null,
  title: '门店交易管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/storeTransactionManager/',
  route: '/storeTransactionManager',
  order: null,
  inNav: true,
  children: [
    {
      title: '套餐管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/storeTransactionManager/comboManager',
      filePath: 'plugin/storeTransactionManager/view/comboManager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '包厢/时段',
      type: 'view',
      name: 'boxAndTIme',
      route: '/storeTransactionManager/boxAndTIme',
      filePath: 'plugin/storeTransactionManager/view/boxAndTIme/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '价目表管理',
      type: 'view',
      name: 'priceList',
      route: '/storeTransactionManager/priceList',
      filePath: 'plugin/storeTransactionManager/view/priceList/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '预订设置',
      type: 'view',
      name: 'presetSet',
      route: '/storeTransactionManager/presetSet',
      filePath: 'plugin/storeTransactionManager/view/presetSet/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
